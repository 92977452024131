body{
  padding: 15px;
  /* background-color: #222222; */
  /* color: aliceblue; */
}
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #fafafa;
  /* background-color: #222222; */
}

tr:hover {
  background-color: #ddd;
}

input{
  margin-right: 30px;
}
button{
  margin-right: 30px;
}

#tableButtons{
  margin-right: 0px !important;
  margin-bottom: 20px;
}

#tableButtons input {
  margin-right: 10px !important;
}
#tableButtons button {
  margin-right: 10px !important;
}
#tableButtons select {
  margin-right: 10px !important;
}

#error{
  color:red
}
header{
  text-align: center;
}
.iconFas :hover{
  cursor: pointer;
}
.recentChanged{
  background-color: red !important;
}
.checkbox{
  font-size: 14px;
  text-align: center;
}
.hide{
  display: none;
}
.show{
  display: all;
}